import React from "react"

import { withSizeGuide } from "./withSizeGuide"
import { Tabs, TabPanels, TabPanel } from "@reach/tabs"
import {
  Wrapper,
  Title,
  Description,
  StyledTabList,
  StyledTab,
  CategoryWrapper,
  CategoryTitle,
  Tables,
  StyledTable,
  TablesWrapper,
  ImageWrapper,
  BorderWrapper,
} from "./SizeGuideStyles"
import { ImageVideo } from "../ImageVideo/ImageVideo"

export const SizeGuide = withSizeGuide(({ title, content, tabs }) => (
  <Wrapper>
    <Title>{title}</Title>
    <Description>{content}</Description>
    <div>
      <Tabs>
        {({ selectedIndex }) => (
          <>
            <StyledTabList>
              {tabs &&
                tabs.map((tab, index) => (
                  <StyledTab isSelected={selectedIndex === index} key={tab._key}>
                    {tab.title}
                  </StyledTab>
                ))}
            </StyledTabList>
            <BorderWrapper>
              <TabPanels>
                {tabs &&
                  tabs.map(tab => (
                    <TabPanel key={tab._key}>
                      {tab?.categories?.map(category => (
                        <CategoryWrapper key={category._key}>
                          <CategoryTitle>{category.title}</CategoryTitle>
                          <TablesWrapper>
                            <Tables>
                              {category?.chart?.tables?.map(table => (
                                <StyledTable key={table._key} chart={table.table} layout={"sizeChart"} />
                              ))}
                            </Tables>
                            {category?.image ? (
                              <ImageWrapper>
                                <ImageVideo imageVideo={category?.image} />
                              </ImageWrapper>
                            ) : null}
                          </TablesWrapper>
                        </CategoryWrapper>
                      ))}
                    </TabPanel>
                  ))}
              </TabPanels>
            </BorderWrapper>
          </>
        )}
      </Tabs>
    </div>
  </Wrapper>
))
